<template>
    <div>
        <PlanRenew v-if="roleName=='Admin'"/>
        <VersionMsg/>
        <PackageUsageInfo v-if="roleName=='Admin'"/>
        <v-footer padless light :color="getFooterColor">
            <v-col class="text-right font-weight-regular">
                {{footerText}}
            </v-col>
        </v-footer>
    </div>
</template>

<script>
import VersionMsg from './VersionMsg.vue'
import PlanRenew from './PlanRenew.vue'
import PackageUsageInfo from './PackageUsage.vue'
export default {
    name:'Footer',
    components:{
        VersionMsg,
        PlanRenew,
        PackageUsageInfo
    },
    computed:{
        getFooterColor:function(){
            return this.$store.getters['settings/footerColor']
        },
        footerText:function(){
            return this.$store.getters['settings/footerText']
        },
        roleName(){
            return this.$store.getters['dashboard/userInfo'].roleName
        }
    }
}
</script>