import axios from 'axios'
import config from '../../../public/config'

const state={
    questions:[],
    confirmation:{},
    quiz:{},
    quizes:[],
    imagePath:'',
    videoPath:'',
    webCamVideoPath:'',
    localDrive:false,
    youTubePath:false,
    submitVideo:false,
    endVideo:false,
    startVideo:false,
    videoDuration:0,
    showQuestionTab:false
};

const getters={
    questions:state=>state.questions,
    confirmation:state=>state.confirmation,
    quiz:state=>state.quiz,
    quizes:state=>state.quizes,
    imagePath:state=>state.imagePath,
    webCamVideoPath:state=>state.webCamVideoPath,
    localDrive:state=>state.localDrive,
    youTubePath:state=>state.youTubePath,
    submitVideo:state=>state.submitVideo,
    endVideo:state=>state.endVideo,
    startVideo:state=>state.startVideo,
    videoDuration:state=>state.videoDuration,
    showQuestionTab:state=>state.showQuestionTab,
};

const actions={
    fetchQuizWithQuestionsCount({commit},userId) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/quiz/GetQuizWithQuestionCount/${userId}`)
            .then((response)=>{
                commit('setQuizesWithQuestionsCount',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchQuizQuestions({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/GetQuizQuestionList/${id}`)
            .then((response)=>{
                commit('setQuestions',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    createQuestion({commit},objQuestion) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/CreateQuizQuestion',objQuestion)
            .then((response)=>{
                commit('newQuestion',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateQuestion({commit},objQuestion) {
        return new Promise((resolve,reject)=>{
            axios.put(config.hostname+'/api/Quiz/UpdateQuizQuestion',objQuestion)
            .then((response)=>{
                commit('editQuestion',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    deleteQuestion({commit},id) {
        return new Promise((resolve,reject)=>{
            axios.delete(config.hostname+`/api/Quiz/DeleteSingleQuizQuestion/${id}`)
            .then((response)=>{
                commit('deleteSingleQuestion',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchSingleQuizTopic({commit},quizId) {
        return new Promise((resolve,reject)=>{
            axios.get(config.hostname+`/api/Quiz/GetSingleQuiz/${quizId}`)
            .then((response)=>{
                commit('setSingleQuiz',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    storeSingleQuiz({commit},obj) {
        commit('setSingleQuiz',obj)
    },
    storeWebCamPath({commit},path) {
        commit('setWebCamPath',path)
    },
    openLocalDrive({commit},val) {
        commit('setLocalDrive',val)
    },
    openYouTubePath({commit},val) {
        commit('setYouTubePath',val)
    },
    storeSubmitVideo({commit},val) {
        commit('setsubmitVideo',val)
    },
    storeEndVideo({commit},val) {
        commit('setEndVideo',val)
    },
    storeStartVideo({commit},val) {
        commit('setStartVideo',val)
    },
    storeVideoDuration({commit},val) {
        commit('setVideoDuration',val)
    },
    storeShowQuestionTab({commit},val) {
        commit('setShowQuestionTab',val)
    },
    uploadImage({commit},objFormData) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/UploadQuestionImage',objFormData)
            .then((response)=>{
                commit('imageUpload',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    uploadVideo({commit},objFormData) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/UploadQuestionVideo',objFormData)
            .then((response)=>{
                commit('videoUpload',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    uploadRecordedVideo({commit},objFormData) {
        return new Promise((resolve,reject)=>{
            axios.post(config.hostname+'/api/Quiz/UploadRecordedVideo',objFormData)
            .then((response)=>{
                commit('videoUpload',response.data)
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    
};

const mutations ={
    setQuestions:(state,questions)=>{
        state.questions=questions
    },
    deleteSingleQuestion:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    setSingleQuiz:(state,obj)=>{
        state.quiz=obj
    },
    setWebCamPath:(state,path)=>{
        state.webCamVideoPath=path
    },
    setLocalDrive:(state,val)=>{
        state.localDrive=val
    },
    setYouTubePath:(state,val)=>{
        state.youTubePath=val
    },
    setsubmitVideo:(state,val)=>{
        state.submitVideo=val
    },
    setEndVideo:(state,val)=>{
        state.endVideo=val
    },
    setStartVideo:(state,val)=>{
        state.startVideo=val
    },
    setVideoDuration:(state,val)=>{
        state.videoDuration=val
    },
    setShowQuestionTab:(state,val)=>{
        state.showQuestionTab=val
    },
    setQuizesWithQuestionsCount:(state,quizes)=>{
        state.quizes=quizes
    },
    imageUpload:(state,data)=>{
        state.imagePath=data.dbPath
    },
    videoUpload:(state,data)=>{
        state.videoPath=data.dbPath
    },
    newQuestion:(state,confirmation)=>{
        state.confirmation=confirmation
    },
    editQuestion:(state,confirmation)=>{
        state.confirmation=confirmation
    },
};

export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}