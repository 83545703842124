<template>
  <div>
    <div class="iframe-container">
      <video ref="audioPlayer" class="video-js responsive-iframe vjs-default-skin"></video>
    </div>    
    <v-btn outlined class="text-capitalize mt-1 mr-1" @click="startRecording()" v-bind:disabled="isStartRecording">{{ $t('startRecording') }}</v-btn>
    <v-btn outlined class="text-capitalize mt-1" @click="stopVideo()" v-bind:disabled="isStopRecording">{{ $t('submitAnswer') }}</v-btn>
  </div>
</template>
      
  <script>
    import WaveSurfer from 'wavesurfer.js'
    import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js'
    WaveSurfer.microphone = MicrophonePlugin
    import videojs from 'video.js'
    import RecordRTC from 'recordrtc'
    import 'video.js/dist/video-js.css'
    import 'videojs-record/dist/css/videojs.record.css'
    import 'vue-video-player/src/custom-theme.css'
    import 'videojs-record/dist/videojs.record.js'

    import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css'
    import 'videojs-wavesurfer/dist/videojs.wavesurfer.js'
    
      export default {
        name: 'VideoRecorder',
        data() {
          return {      
            player: null,
            isStartRecording: false,
            isStopRecording:true,
            options: {
              controls: true,
              fluid: false,
              autoplay: true,
              bigPlayButton: false,
              controlBar: {
                volumePanel: false,
                deviceButton:false,
                recordToggle:false
              },
              plugins: {
                wavesurfer: {
                  backend: 'WebAudio',
                  waveColor: '#36393b',
                  progressColor: 'black',
                  debug: true,
                  cursorWidth: 1,
                  displayMilliseconds: true,
                  hideScrollbar: true,
                  plugins: [
                      // enable microphone plugin
                      WaveSurfer.microphone.create({
                          bufferSize: 4096,
                          numberOfInputChannels: 1,
                          numberOfOutputChannels: 1,
                          constraints: {
                              video: false,
                              audio: true
                          }
                      })
                  ]
                },
                record: {
                  image:false,
                  audio: true,
                  video: false,
                  maxLength: this.$store.getters['settings/webCamVideoLength'],
                  debug: true,
                },
              },
            },
          }
        },
        mounted() {
          this.player = videojs(this.$refs.audioPlayer,
          this.options, () => {
            var msg = 'Using video.js ' + videojs.VERSION +
                ' with videojs-record ' + videojs.getPluginVersion('record') +
                ' and recordrtc ' + RecordRTC.version;
            videojs.log(msg);
          })
          this.player.on('deviceReady', () => {
            this.player.record().start();
            console.log('device ready:');
          });
    
          this.player.on('deviceError', () => {
            console.log('device error:', this.player.deviceErrorCode);
          });
    
          this.player.on('error', (element, error) => {
            console.error(error);
          });
  
          this.player.on('startRecord', () => {
            console.log('started recording!');
          });
    
          this.player.on('finishRecord', () => {
            console.log('finished recording: ', this.player.recordedData);
            this.submitVideo()
          });
        },
        beforeDestroy() {
          if (this.player) {
            this.player.dispose()
          }
        },
        methods: {
          startRecording() {
            this.isStartRecording = true;
            this.isStopRecording=false;
            this.player.record().getDevice();
          },
          stopVideo(){
            this.isStopRecording=true;
            this.player.record().stop();
          },
          submitLastPlayedAnswer(){
            if(this.$store.getters['dashboard/quizEndStatus']){           
              this.$store.dispatch('dashboard/applyQuizEndStatus',false)
              this.player.record().stop();
            }
          },
          submitVideo() {
              this.$store.dispatch('dashboard/applyLoading')
              this.$store.dispatch('question/storeStartVideo',true)
              var data = this.player.recordedData;
              var formData = new FormData();
              var formattedName=data.name.split('.')[0].concat('.oga')
              formData.append('audio', data, formattedName);
                 
              this.$store.dispatch('question/uploadVideo',formData)              
              .then(response=>{
                  this.$store.dispatch('dashboard/cancelLoading')
                  this.$store.dispatch('question/storeStartVideo',false)
                      if(response.status==200){
                        this.$store.dispatch('question/storeWebCamPath','/'+response.data.dbPath)
                        this.$store.dispatch('question/storeSubmitVideo',true)
                      }
                  })
              .catch(err=>{
                this.$store.dispatch('dashboard/cancelLoading')
                this.$store.dispatch('question/storeStartVideo',false)
                console.log(err)
              })
          }
        },
        computed:{
          checkQuizEndStatus(){
            return this.$store.getters['dashboard/quizEndStatus']
          }
        },
        watch:{
          checkQuizEndStatus(){
            this.submitLastPlayedAnswer()
          }
        }
      }
    </script>
      
    <style scoped>
    .video-js {
      width: 100%;
      height: 100%;
    }
    .iframe-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
    }
    
    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
    </style>
      